import { useCallback, useMemo } from "react";
import { useGetUserProfilesForChatQuery } from "src/gql/generated/graphql";
import { gql } from "urql";

const USER_PROFILE_FIELD = gql`
  fragment UserProfileField on UserProfile {
    userDatastoreId
    presignedIcon
  }
`;

gql`
  ${USER_PROFILE_FIELD}

  query getUserProfilesForChat($first: Int!) {
    userProfiles(first: $first) {
      nodes {
        ...UserProfileField
      }
    }
  }
`;

export type UseUsers = {
  getIconImage: (userId: string | undefined) => string;
};

export const useUsers = (): UseUsers => {
  const [{ data }] = useGetUserProfilesForChatQuery({
    variables: { first: 150 },
  });

  const profiles = useMemo(() => {
    return new Map(
      data?.userProfiles.nodes.map((node) => [node.userDatastoreId, node]) ?? []
    );
  }, [data]);

  const getIconImage = useCallback(
    (userId: string | undefined) => {
      if (!userId) return "";
      const profile = profiles.get(userId);
      return profile?.presignedIcon ?? "";
    },
    [profiles]
  );

  return {
    getIconImage,
  };
};
