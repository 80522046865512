import { API_PASS } from "src/const";
import { Cookies } from "react-cookie";
import { cookieKeyVoytToken } from "src/util/token";

/** Voytインカムスピーチ送信処理*/
export const requestSpeech = async (
  groupId: string,
  text: string,
  userRole: string | undefined
) => {
  const url = process.env.REACT_APP_INCOM_FUNCTION_API_URL;
  if (!url) {
    console.error(
      "環境変数 REACT_APP_INCOM_FUNCTION_API_URLが設定されていません"
    );
    return;
  }
  const cookie = new Cookies();
  const token = cookie.get(cookieKeyVoytToken);
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (!token) {
    console.error("tokenがありません");
    return [await 400, undefined];
  }
  headers.append("X-AUTH-TOKEN", token);

  try {
    const params = { group_id: groupId };
    const apiUrl = `${url}/${API_PASS.SPEECH}`;
    // apiUrlにparamsを追加する
    const urlWithParams = new URL(apiUrl);
    urlWithParams.search = new URLSearchParams(params).toString();
    const res = await fetch(urlWithParams, {
      method: "POST",
      headers,
      body: JSON.stringify({
        recog_content: text,
        meta_data: { memo: { memo_title: "userRole", memo_body: userRole } },
      }),
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};
