import { limitToLast, onValue, query, ref, set } from "firebase/database";
import { useEffect, useMemo, useRef, useState } from "react";
import { MeRoomFieldFragment } from "src/gql/generated/graphql";
import {
  ALLChat,
  Chat,
  ChatGroupType,
  ChatRoomCheckTime,
  ChatType,
  ChatUser,
  UserStatusType,
  messageType,
} from "src/interface/Chat";
import Sound from "src/sound/chat.mp3";
import { useAtom, useAtomValue } from "jotai";
import { meAtom } from "src/pages/Main/atoms/me";
import { selectedChatAtom } from "src/pages/Main/atoms/selectedChat";
import { isNoticeMuteAtom } from "src/pages/Main/atoms/isNoticeMute";
import { replaceUserId } from "src/util/getString";
import { useVoytIntercom } from "src/pages/Main/hooks/useVoytIntercom";
import { MESSAGE_TEXT_LIMIT, TEXT } from "src/const";
import { databaseAtom } from "src/pages/Main/atoms/firebase";
import {
  getAccessDocUserData,
  getAccessDocUserChatRoomCheckTime,
  getAccessDocComapnayLastChatTime,
} from "src/util/realtimedb/accessDoc";
import { meInfoAtom } from "src/pages/Main/atoms/me";
import { updateUser } from "src/util/realtimedb/updateUserInfo";
import { getNowTime } from "src/util/getTime";
import { isToastOnAtom } from "src/pages/Main/atoms/isToastOn";

export const useMain = (
  isViewOnFrame: boolean,
  downScroll: () => void,
  shopId: string | null
) => {
  const [selectedChat, setSelectedChat] = useAtom(selectedChatAtom);
  const [unreadAllChatMessages, setUnreadAllChatMessages] = useState<number>(0);
  const [unreadMessagesOnRoom, setUnreadMessagesOnRoom] = useState<
    {
      roomId: string;
      roomName: string;
      unreadMessgesNum: number;
      lastChat?: Chat;
    }[]
  >([]);
  const callChatTextRef = useRef<HTMLTextAreaElement>(null);
  const [chatList, setChatList] = useState<Chat[]>([]);
  const [allChat, setAllChat] = useState<ALLChat | null>(null);
  const [chatRoomCheckTime, setChatRoomCheckTime] =
    useState<ChatRoomCheckTime | null>(null);
  const [isView, setIsView] = useState<boolean>(true);
  const [isNoticeMute, setIsNoticeMute] = useAtom(isNoticeMuteAtom);
  const [isToastOn, setIsToastOn] = useAtom(isToastOnAtom);
  const me = useAtomValue(meAtom);
  const database = useAtomValue(databaseAtom);
  const [meInfo, setMeInfo] = useAtom(meInfoAtom);

  const rooms: MeRoomFieldFragment[] = useMemo(() => {
    return me?.roomGroup?.rooms ?? [];
  }, [me]);

  const companyName: string = useMemo(() => {
    return me?.company?.name ?? "";
  }, [me]);

  const userName: string = useMemo(() => {
    return me?.name ?? "";
  }, [me]);

  const companyId: string = useMemo(() => {
    return me?.company.datastoreId ?? "";
  }, [me]);

  const {
    voytIntercomGroupList,
    sendIntercomMessage,
    lastGetChatTime,
    lastGetChatTimeOnRoom,
  } = useVoytIntercom(allChat, setAllChat);

  useEffect(() => {
    // voytIncomGroupListの一番最初を指定する
    if (voytIntercomGroupList.length === 0) return;
    // allChatがない場合は何もしない
    if (!allChat || !Object.keys(allChat).length) return;
    if (!selectedChat) setSelectedChat(voytIntercomGroupList[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [voytIntercomGroupList, allChat, selectedChat]);

  // tokenが別のカンパニーの場合はundefinedで返却される
  const userId: string | undefined = useMemo(() => {
    return replaceUserId(me?.datastoreId ?? "");
  }, [me]);

  const getUnreadMessges = (
    chatRoomCheckTimes: ChatRoomCheckTime | null,
    allChat: ALLChat | null,
    chatType: ChatType,
    selectedRoomId?: string
  ) => {
    if (chatRoomCheckTimes && allChat) {
      const chatRoomCheckTimeOnRoom = Object.values(chatRoomCheckTimes).find(
        (chatRoomCheckTime) =>
          chatRoomCheckTime.chatType === chatType &&
          chatRoomCheckTime.id === selectedRoomId
      );
      
      if (chatType === ChatType.Room) {
        if (
          chatRoomCheckTimeOnRoom &&
          allChat &&
          selectedRoomId &&
          allChat[selectedRoomId]
        ) {
          const roomChat = allChat[selectedRoomId];
          const afterTimes = Object.values(roomChat).filter(
            (chat) =>
              new Date(chat.timestamp) >=
                new Date(chatRoomCheckTimeOnRoom?.checkTime) &&
              new Date(chat.timestamp) >=
                new Date(new Date().getTime() - 24 * 60 * 60 * 1000) &&
              chat.chatUserId !== userId
          );
          return afterTimes.length;
        }
      }
    }
    return 0;
  };

  const getLastMessageFromChat = (allChat: ALLChat, roomId: string) => {
    if (!allChat || !allChat[roomId]) return undefined;
    const roomChat = allChat[roomId];
    const chatList = Object.values(roomChat);
    const lastChat = chatList[chatList.length - 1];
    return lastChat
  };

  const intercomGroupList = useMemo(() => {
    if (!userId || !companyId || !chatRoomCheckTime || !allChat) return [];
    const tmpintercomGroupList: ChatGroupType[] = [];
    voytIntercomGroupList.forEach((room) => {
      tmpintercomGroupList.push({
        id: room.id,
        name: room?.name,
        chatType: ChatType.Room,
        chatUsers: [],
        isSelected: selectedChat === room.id ? true : false,
        unreadMessgesNum: getUnreadMessges(
          chatRoomCheckTime,
          allChat,
          ChatType.Room,
          room.id
        ),
      });
    });
    let unreadMessgesNum = 0;
    // 新着メッセージ内容を共有するため
    const unreadMessgesRoom: {
      roomId: string;
      roomName: string;
      unreadMessgesNum: number;
      lastChat?: Chat;
    }[] = [];
    tmpintercomGroupList.forEach((tmpChatGroup) => {
      if (tmpChatGroup.unreadMessgesNum > 0) {
        const lastChat = getLastMessageFromChat(allChat, tmpChatGroup.id);
        unreadMessgesRoom.push({
          roomId: tmpChatGroup.id,
          roomName: tmpChatGroup.name,
          unreadMessgesNum: tmpChatGroup.unreadMessgesNum,
          lastChat,
        });
      }
      unreadMessgesNum += tmpChatGroup.unreadMessgesNum;
    });
    if (
      !isViewOnFrame &&
      !isNoticeMute &&
      unreadAllChatMessages < unreadMessgesNum
    ) {
      // メッセージが増えた && 通知音ONの時
      const audio = new Audio(Sound);
      audio.volume = 1;
      audio.play();
    }
    setUnreadAllChatMessages(unreadMessgesNum);
    setUnreadMessagesOnRoom(unreadMessgesRoom);
    return tmpintercomGroupList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedChat,
    chatRoomCheckTime,
    allChat,
    rooms,
    companyName,
    userId,
    companyId,
    isNoticeMute,
    unreadAllChatMessages,
    shopId,
    lastGetChatTime,
  ]);

  useEffect(() => {
    if (!userId || !companyId || !database || !me) return;
    // userDataを取得する
    const doc = getAccessDocUserData(companyId);
    const userDataRef = ref(database, doc);

    onValue(
      userDataRef,
      (snapshot) => {
        // 1回しかとる必要がないため
        if (meInfo) return;
        const userData = snapshot.val();
        if (userData) {
          const tmpUsers: ChatUser[] = [];
          if (!userData[userId]) {
            const meInfo = updateUser(
              database,
              me,
              UserStatusType.Wait,
              isNoticeMute === undefined ? true : isNoticeMute,
              isToastOn === undefined ? true : isToastOn
            );
            userData[userId] = { userInfo: meInfo };
          }
          setChatRoomCheckTime(userData[userId].chatRoomCheckTime ?? {});

          Object.keys(userData).forEach((keyUserId: string) => {
            const tmpChatUser: ChatUser = userData[keyUserId].userInfo;
            tmpUsers.push(tmpChatUser);
            if (keyUserId === userId) {
              setMeInfo(tmpChatUser);
              setIsNoticeMute(tmpChatUser?.noticeMute ?? true);
              setIsToastOn(tmpChatUser?.isToastOn ?? true);
            }
          });
        } else {
          updateUser(
            database,
            me,
            UserStatusType.Wait,
            isNoticeMute === undefined ? true : isNoticeMute,
            isToastOn === undefined ? true : isToastOn
            );
          setChatRoomCheckTime(null);
        }
      },
      {
        onlyOnce: true,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [companyId, userId, meInfo]);

  useEffect(() => {
    if (!database || !me) return;
    if (userName && meInfo) {
      updateUser(database, me, UserStatusType.Wait, meInfo.noticeMute ?? true, meInfo.isToastOn ?? true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [userName, meInfo, database]);

  useEffect(() => {
    if (!userId || !companyId || !database) return;
    if (chatRoomCheckTime) return;
    const doc = getAccessDocUserChatRoomCheckTime(
      companyId,
      replaceUserId(userId)
    );
    const userChatRoomCheckTimeDataRef = ref(database, doc);
    onValue(userChatRoomCheckTimeDataRef, (snapshot) => {
      const tmpChatRoomCheckTime = snapshot.val();
      if (tmpChatRoomCheckTime) {
        setChatRoomCheckTime(tmpChatRoomCheckTime);
      }
    });
  }, [companyId, userId, chatRoomCheckTime, database]);

  useEffect(() => {
    if (!selectedChat) return;
    if (!allChat) return;
    if (
      intercomGroupList.find(
        (intercomGroup) => intercomGroup.id === selectedChat
      )
    ) {
      if (allChat) {
        if (!allChat[selectedChat]) return;
        setChatList(Object.values(allChat[selectedChat]));
      } else {
        setChatList([]);
      }
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [
    allChat,
    selectedChat,
    intercomGroupList,
    lastGetChatTime,
    lastGetChatTimeOnRoom,
  ]);

  const requestChat = async () => {
    if (!database) return;
    if (
      selectedChat &&
      callChatTextRef &&
      callChatTextRef.current &&
      callChatTextRef.current.value
    ) {
      if (callChatTextRef.current.value.length > MESSAGE_TEXT_LIMIT) {
        alert(TEXT.MESSAGE.CHARACTER_COUNT_OVER_ALERT);
        return;
      }
      const nowTime = getNowTime();
      if (
        intercomGroupList.find(
          (intercomGroup) => intercomGroup.id === selectedChat
        )
      ) {
        // TODO: APIを実行する
        sendIntercomMessage(selectedChat, callChatTextRef.current.value);
      }
      const doc = getAccessDocComapnayLastChatTime(companyId);
      set(ref(database, doc), nowTime);
      checkChatRoom(selectedChat, nowTime);
      callChatTextRef.current.value = "";
      setTimeout(() => {
        downScroll();
      }, 500);
    }
  };

  const checkChatRoom = (tmpChatId?: string, time?: string): boolean => {
    // chatRoomをフロントから閲覧した時刻を保存する
    if (!database) return false;
    if (!userId || !companyId) return false
    if (!tmpChatId && !selectedChat) return false;
    const chatId = tmpChatId ?? selectedChat;
    const doc = getAccessDocUserChatRoomCheckTime(
      companyId,
      replaceUserId(userId)
    );
    set(ref(database, `${doc}/${chatId}`), {
      id: chatId,
      checkTime: time ?? getNowTime(),
      chatType: ChatType.Room,
    });
    return true;
  };

  return {
    companyId,
    userId,
    chatList,
    setChatList,
    intercomGroupList,
    requestChat,
    callChatTextRef,
    unreadAllChatMessages,
    companyName,
    isView,
    setIsView,
    userName,
    checkChatRoom,
    chatRoomCheckTime,
    unreadMessagesOnRoom,
    lastGetChatTime,
  };
};
