import { atom } from "jotai";
import { MeFieldFragment } from "src/gql/generated/graphql";
import { ChatUser } from "src/interface/Chat";
import { VoytUser } from "src/interface/Voyt";

export const meAtom = atom<MeFieldFragment | undefined>(undefined);
export const meVoytAtom = atom<VoytUser | undefined>(undefined);
// realtime dbに保存されている自身の情報
export const meInfoAtom = atom<ChatUser | undefined>(undefined);

/** 自身の情報を取得済みであるか */
export const hasMeAtom = atom((get) => get(meAtom) !== undefined);
