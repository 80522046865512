import { Box, Icon, Text, Flex } from "@chakra-ui/react";
import { FC, useRef, useState, useEffect } from "react";
import { TEXT } from "src/const";
import { useAtomValue } from "jotai";
import { errorTypeAtom, ErrorType } from "src/pages/Main/atoms/errorType";
import { FiAlertTriangle } from "react-icons/fi";

export const ErrorArea: FC = () => {
  const errorType = useAtomValue(errorTypeAtom);
  const error =
    errorType === ErrorType.VOYT_LOGIN_FAILED
      ? TEXT.ERROR.ERROR_VOYT_API
      : TEXT.ERROR.ERROR_VOYT_API_NO_GROUP;
  const outerBoxRef = useRef<HTMLDivElement>(null);
  const [outerBoxHeight, setOuterBoxHeight] = useState(0);

  // 外側のBoxの高さを取得して中央配置を計算
  useEffect(() => {
    if (outerBoxRef && outerBoxRef.current) {
      setOuterBoxHeight(outerBoxRef.current.clientHeight);
    }
  }, [outerBoxRef]);

  console.log("outerBoxHeight", outerBoxHeight);
  
  return (
    <Box
      height="100%"
      w="100%"
      ref={outerBoxRef}
      position="relative"
      p="24px 0px"
    >
      <Box
        display="flex"
        h="118px"
        textAlign="center"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        w="100%"
        top={`calc(${
          outerBoxHeight < 400 ? 280 : outerBoxHeight / 2
        }px - 59px)`}
      >
        <Icon
          as={FiAlertTriangle}
          color="rgba(235, 20, 56, 1)"
          fontSize="32px"
        />
        <Box mt="16px">
          <Text fontSize="14px" fontWeight={600}>
            {error.TITLE}
          </Text>
        </Box>
        <Box mt="16px">
          <Text fontSize="12px" fontWeight={300}>
            {error.SUGGEST}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
