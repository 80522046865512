import { cookieKeyToken } from "src/util/token";
import { useEffect } from "react";
import { Cookies } from "react-cookie";
import { PATH } from "src/const";
/** tokenをチェックしてログイン画面に遷移させる処理
 */
export const useToken = () => {
  useEffect(() => {
    const cookie = new Cookies();
    const token = cookie.get(cookieKeyToken);
    if (!token) {
      window.location.href = PATH.LOGIN;
    }
  }, []);
};
