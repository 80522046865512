import { Box, Text, Image } from "@chakra-ui/react";
import { FiHelpCircle } from "react-icons/fi";
import logo from "src/images/ruraLogo.svg";

export const Header = ({ text }: { text: string }) => {
  return (
    <Box>
      <Box
        position="absolute"
        top="10px"
        right="15px"
        width="20px"
        height="20px"
        _hover={{
          background: "rgba(0, 0, 0, 0.13333)",
        }}
        p="3px"
        cursor="pointer"
        borderRadius="4px"
        onClick={() => {
          window.open(
            "https://support.timeleap-rura.com/",
            "_blank",
            "noreferrer"
          );
        }}
      >
        <FiHelpCircle />
      </Box>
      <Box width="100%" pt="100px">
        <Image
          src={logo as string}
          alt="タイムリープロゴ"
          w="177.5px"
          h="48px"
          m="auto"
        />
      </Box>
      <Box mt="64px" w="177.5px" m="8px auto 32px auto">
        <Text fontSize="20px">インカム機能</Text>
      </Box>
    </Box>
  );
};
