import React from "react";
import ChatPage from "./pages/Main";
import { Login } from "src/pages/Login";
import Setting from "src/pages/Setting";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PATH } from "src/const";
import "src/App.css";
import { useCheckAuth } from "./hooks/useCheckAuth";

function App() {
  useCheckAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PATH.CHAT} element={<ChatPage />} />
        <Route path={PATH.LOGIN} element={<Login loginType="default" />} />
        <Route path={PATH.SHOP_LOGIN} element={<Login loginType="shop" />} />
        <Route path={PATH.SETTING} element={<Setting />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
