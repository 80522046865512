import { MeRoomFieldFragment, useMeQuery } from "src/gql/generated/graphql";
import { useState, useEffect } from "react";
import { gql } from "urql";

export const MeQuery = gql`
  fragment FeatureToggle on FeatureToggle {
    feature
    enabled
  }
  fragment MePcField on PC {
    id
    datastoreId
    name
    mergedFeatureToggles {
      feature
      enabled
    }
  }
  fragment MeRoomField on Room {
    id
    datastoreId
    name
    pcs {
      ...MePcField
    }
  }

  fragment MeField on User {
    id
    datastoreId
    name
    role
    profile {
      presignedIcon
    }
    roomGroup {
      rooms {
        ...MeRoomField
      }
    }
    company {
      datastoreId
      name
      featureToggles {
        ...FeatureToggle
      }
    }
  }

  query me {
    me {
      ...MeField
    }
  }
`;

export const useSetting = () => {
  const [result] = useMeQuery();
  const [roomId, setRoomId] = useState<string>("");
  const [rooms, setRooms] = useState<MeRoomFieldFragment[]>([]);
  const [companyName, setCompanyName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    if (result.data?.me === undefined) return;
    setUserName(result.data.me.name);
    setCompanyName(result.data.me.company.name);
    setRooms(result.data.me.roomGroup?.rooms ?? []);
    if (
      result.data.me.roomGroup?.rooms &&
      result.data.me.roomGroup?.rooms.length > 0
    ) {
      setRoomId(result.data.me.roomGroup?.rooms[0].datastoreId);
    }

    // window.location.href = `${path}?user_id=${userId}`;
  }, [result]);

  return { userName, roomId, setRoomId, rooms, companyName };
};
