import { Database } from "firebase/database";
import { ref, set } from "firebase/database";
import { getNowTime } from "../getTime";
import { ChatUser, UserStatusType } from "src/interface/Chat";
import { getAccessDocUserInfo } from "src/util/realtimedb/accessDoc";
import { MeFieldFragment } from "src/gql/generated/graphql";
import { replaceUserId } from "src/util/getString";

export const noticeMute = (
  database: Database,
  companyId: string,
  meInfo: ChatUser,
  isNoticeMute: boolean
) => {
  const doc = getAccessDocUserInfo(companyId, replaceUserId(meInfo.userId));
  set(ref(database, doc), {
    ...meInfo,
    timestamp: getNowTime(),
    noticeMute: isNoticeMute,
  });
};

export const toastOn = (
  database: Database,
  companyId: string,
  meInfo: ChatUser,
  isToastOn: boolean
) => {
  const doc = getAccessDocUserInfo(companyId, replaceUserId(meInfo.userId));
  set(ref(database, doc), {
    ...meInfo,
    timestamp: getNowTime(),
    isToastOn: isToastOn,
  });
};

export const updateUser = (
  database: Database,
  me: MeFieldFragment,
  status: UserStatusType,
  noticeMute: boolean,
  isToastOn: boolean
) => {
  const userData = {
    userId: replaceUserId(me.datastoreId),
    name: me.name,
    timestamp: getNowTime(),
    status: status,
    noticeMute,
    isToastOn,
  };
  // ユーザのステータスを更新する
  const doc = getAccessDocUserInfo(
    me.company.datastoreId,
    replaceUserId(me.datastoreId)
  );
  set(ref(database, doc), userData);
  return userData;
};

export const getUser = (users: ChatUser[], userId: string | undefined) => {
  const user = users.find((user) => user?.userId === userId);
  return user;
};
