export const addZero = (num: number) => {
  if (num.toString().length === 1) {
    return `0${num.toString()}`;
  }
  return num.toString();
};

export const isNotBlank = (v: string | null | undefined): v is string => {
  if (v === undefined || v === null) return false;
  if (v === "") return false;
  return true;
};

export const isBlank = (v: string | null | undefined): v is null | undefined =>
  !isNotBlank(v);
