import { useAtom, useAtomValue } from "jotai";
import { meAtom } from "src/pages/Main/atoms/me";
import { receptionPcTogglesAtom } from "src/pages/Main/atoms/receptionPcToggles";
import { Feature, FeatureToggle } from "src/gql/generated/graphql";

/**
 * 機能トグル
 *
 * @deprecated
 * カンパニーレベルの ON / OFF を知りたい場合は useCompanyFeatureToggle、
 * 店鋪個別の ON / OFF を知りたい場合は usePcFeatureToggle を使用してください。
 */
export const useFeatureToggle = () => {
  const me = useAtomValue(meAtom);
  const [receptionPcToggles, setReceptionPcToggles] = useAtom(
    receptionPcTogglesAtom
  );
  const addReceptionPcToggles = (toggles: Array<FeatureToggle>) => {
    setReceptionPcToggles([...toggles]);
  };

  const clearReceptionPcToggles = () => {
    setReceptionPcToggles([]);
  };

  const companyFeatureToggles = me?.company.featureToggles;

  const isFeatureToggle = (
    companyFeatureToggles: Array<FeatureToggle>,
    feature: Feature,
    pcFeatureToggles?: Array<FeatureToggle>
  ) => {
    const companyFeatureToggle = companyFeatureToggles.find(
      (companyToggle) => companyToggle.feature === feature
    );
    if (pcFeatureToggles) {
      const pcFeatureToggle = pcFeatureToggles.find(
        (pcToggle) => pcToggle.feature === feature
      );
      if (!companyFeatureToggle?.enabled) return false;
      return pcFeatureToggle?.enabled;
    }
    return companyFeatureToggle?.enabled;
  };

  const isEnable = (feature: Feature) => {
    if (!companyFeatureToggles) return false;
    if (receptionPcToggles.length > 0) {
      return isFeatureToggle(
        companyFeatureToggles,
        feature,
        receptionPcToggles
      );
    }
    return isFeatureToggle(companyFeatureToggles, feature);
  };

  return { isEnable, addReceptionPcToggles, clearReceptionPcToggles };
};
