import {
  Box,
  Flex,
  IconButtonProps,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  TooltipProps,
  Spacer,
} from "@chakra-ui/react";
import { FC, useMemo, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import {
  IoCloseCircleOutline,
  IoReorderThreeOutline,
  IoVolumeMediumOutline,
  IoVolumeMuteOutline,
} from "react-icons/io5";
import { PATH, TEXT } from "src/const";
import { ChatIconButton } from "src/pages/Main/components/ChatIconButton";
import { isNoticeMuteAtom } from "src/pages/Main/atoms/isNoticeMute";
import { useAtom, useAtomValue } from "jotai";
import { meAtom, meInfoAtom } from "src/pages/Main/atoms/me";
import { databaseAtom } from "src/pages/Main/atoms/firebase";
import { MdHeadphones } from "react-icons/md";
import { isCallingAtom, callingUsersAtom } from "src/pages/Main/atoms/calling";
import { useFeatureToggle } from "src/hooks/useFeatureToggle";
import { Feature } from "src/gql/generated/graphql";
import { cookieKeyToken, cookieKeyVoytToken } from "src/util/token";
import { isDevEnv } from "src/util/env";
import { TooltipButton } from "src/components/TooltipButton";
import {isToastOnAtom} from "src/pages/Main/atoms/isToastOn";
import { FaRegBell } from "react-icons/fa";
import { FaRegBellSlash } from "react-icons/fa";
import { toastOn } from "src/util/realtimedb/updateUserInfo";

const NoticeTooltipProps: TooltipProps = {
  label: TEXT.TOOLTIP.TURN_OFF_NOTIFICATION_SOUND_TEXT,
  placement: "top",
  children: [],
};

const NoticeMuteTooltipProps: TooltipProps = {
  label: TEXT.TOOLTIP.TURN_ON_NOTIFICATION_SOUND_TEXT,
  placement: "top",
  children: [],
};

const ToastOnTooltipProps: TooltipProps = {
  label: TEXT.TOOLTIP.TURN_OFF_TOAST_TEXT,
  placement: "top",
  children: [],
};

const ToastOffTooltipProps: TooltipProps = {
  label: TEXT.TOOLTIP.TURN_ON_TOAST_TEXT,
  placement: "top",
  children: [],
};



const baseIconButtonProps: IconButtonProps = {
  size: "sm",
  bg: "none",
  zIndex: "5",
  color: "#FFFFFF",
  cursor: "pointer",
  "aria-label": "mirroring video",
  _hover: {
    bg: "rgba(65, 99, 123, 0.08)",
  },
  _active: { bg: "rgba(65, 99, 123, 0.16)" },
};

const muteProps: IconButtonProps = {
  ...baseIconButtonProps,
  top: 0,
  right: 0,
  icon: <IoVolumeMuteOutline color="#EB1438" fontSize="24px" />,
};

const notMuteProps: IconButtonProps = {
  ...baseIconButtonProps,
  top: 0,
  right: 0,
  icon: <IoVolumeMediumOutline color="#00A0E9" fontSize="24px" />,
};

const toastOnProps: IconButtonProps = {
  ...baseIconButtonProps,
  top: 0,
  right: 0,
  icon: <FaRegBell color="#00A0E9" fontSize="20px" />,
};

const toastOffProps: IconButtonProps = {
  ...baseIconButtonProps,
  top: 0,
  right: 0,
  icon: <FaRegBellSlash color="#EB1438" fontSize="24px" />,
};

export const ChatHeader: FC<{
  chatName: string | undefined;
  shopId: string | null;
  closeChat: () => void;
  setIsSideBar: (isSideBar: boolean) => void;
  changeSideBar: (isSideBar: boolean) => void;
  isSideBar: boolean;
  getIconImage: (userId: string) => string | null | undefined;
  lastGetChatTime: string | undefined;
  isError: boolean;
}> = ({
  chatName,
  closeChat,
  setIsSideBar,
  changeSideBar,
  isSideBar,
  getIconImage,
  shopId,
  lastGetChatTime,
  isError,
}) => {
  const callingAudioRef = useRef<HTMLAudioElement>(null);

  const isViewOnFrame = window !== window.parent;
  const [isNoticeMute, setIsNoticeMute] = useAtom(isNoticeMuteAtom);
  const [isToastOn, setIsToastOn] = useAtom(isToastOnAtom);
  const [isCalling, setIsCalling] = useAtom(isCallingAtom);
  const meInfo = useAtomValue(meInfoAtom);
  const me = useAtomValue(meAtom);
  const database = useAtomValue(databaseAtom);
  const callingUsers = useAtomValue(callingUsersAtom);
  const companyId: string = useMemo(() => {
    return me?.company.datastoreId ?? "";
  }, [me]);

  const userName: string = useMemo(() => {
    return me?.name ?? "";
  }, [me]);

  const userId: string = useMemo(() => {
    return me?.datastoreId ?? "";
  }, [me]);
  const { isEnable } = useFeatureToggle();

  // チャット通話の機能トグル
  const viewChatCalling = isEnable(Feature.ChatCalling);

  const onProfile = () => {
    const url = `${process.env.REACT_APP_RURA_MANAGEMENT_URL}/profile`;
    window.open(url, "_blank", "noreferrer");
    return;
  };

  const logout = () => {
    if (!isViewOnFrame) {
      const cookie = new Cookies();
      cookie.remove(cookieKeyToken, {
        maxAge: 10 * 24 * 60 * 60,
        path: "/",
        secure: true,
        domain: isDevEnv() ? "localhost" : ".rura.tokyo",
      });
      cookie.remove(cookieKeyVoytToken, {
        maxAge: 10 * 24 * 60 * 60,
        path: "/",
        secure: true,
        domain: isDevEnv() ? "localhost" : ".rura.tokyo",
      });
      localStorage.removeItem("draft_messages");
      window.location.href = shopId ? PATH.SHOP_LOGIN : PATH.LOGIN;
    } else {
      // iframe上なので、閉じるmessageを送信する
      closeChat();
    }
    return;
  };

  const [isViewCallingUsers, setIsViewCallingUsers] = useState<boolean>(false);

  return (
    <Box w="100%">
      <audio
        ref={callingAudioRef}
        src={`${process.env.PUBLIC_URL}/sound/calling.mp3`}
      />
      <Box
        height="48px"
        borderBottom="1px solid rgba(0,0,0,0.1)"
        p="8px"
        backgroundColor="rgba(244, 246, 248, 1)"
        position="relative"
      >
        {!isSideBar &&
          !isError && (
            <Box
              position="absolute"
              left="8px"
              top="50%"
              transform="translate(0, -50%)"
            >
              <ChatIconButton
                onClick={() => {
                  setIsSideBar(true);
                  changeSideBar(true);
                }}
                ariaLabel={"close"}
                icon={<IoReorderThreeOutline fontSize="20px" />}
              />
            </Box>
          )}
        <Flex alignItems="center" justifyContent="space-between" pl="8px">
          <Box ml={isSideBar ? "0px" : "40px"} className="container">
            <Text fontSize="14px" fontWeight={600} color="rgba(55, 53, 47, 1)">
              インカムメッセージ
            </Text>
          </Box>
          <Flex alignItems="center" justifyContent="flex-end">
            {/* <DeviceSettingButton /> */}
            {/* <TooltipButton
              toolTipProps={
                isNoticeMute ? NoticeMuteTooltipProps : NoticeTooltipProps
              }
              iconButtonProps={isNoticeMute ? muteProps : notMuteProps}
              handleClickButton={() => {
                if (!meInfo || !database) return;
                setIsNoticeMute(!isNoticeMute);
                noticeMute(database, companyId, meInfo, !isNoticeMute);
              }}
            /> */}
            <TooltipButton
              toolTipProps={
                isToastOn ? ToastOnTooltipProps : ToastOffTooltipProps
              }
              iconButtonProps={isToastOn ? toastOnProps : toastOffProps}
              handleClickButton={() => {
                if (!meInfo || !database) return;
                setIsToastOn(!isToastOn);
                toastOn(database, companyId, meInfo, !isToastOn);
                // TODO: 通知の設定を変更する処理を追加
                // noticeMute(database, companyId, meInfo, !isNoticeMute);
              }}
            />
            {isViewOnFrame ? (
              <ChatIconButton
                ariaLabel={"close"}
                icon={<IoCloseCircleOutline fontSize="22px" />}
                onClick={closeChat}
                ml="8px"
              />
            ) : (
              <Menu>
                <MenuButton
                  p="2px 6px 2px 2px"
                  borderRadius="2"
                  marginLeft="12px"
                  cursor="pointer"
                  _hover={{
                    background: "darkRgba.50",
                  }}
                  _active={{
                    background: "darkRgba.100",
                  }}
                >
                  <Flex alignItems="center" className="container">
                    {userId && getIconImage(userId) ? (
                      <Image
                        boxSize="24px"
                        mr="1"
                        borderRadius="full"
                        src={getIconImage(userId) ?? ""}
                        alt="userIcon"
                      />
                    ) : (
                      <Box
                        boxSize="24px"
                        fontSize="10px"
                        mr="1"
                        pt="5px"
                        borderRadius="full"
                        bgColor="dark.100"
                      >
                        {userName?.slice(0, 2)}
                      </Box>
                    )}
                    <Text
                      fontSize="14px"
                      className="item"
                      maxWidth="120px"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {userName}
                    </Text>
                  </Flex>
                </MenuButton>
                <MenuList shadow="md">
                  <MenuItem
                    onClick={logout}
                    _hover={{
                      background: "darkRgba.50",
                    }}
                    _active={{
                      background: "darkRgba.100",
                    }}
                    _focus={{
                      background: "darkRgba.100",
                    }}
                  >
                    ログアウト
                  </MenuItem>
                  <MenuItem
                    onClick={onProfile}
                    _hover={{
                      background: "darkRgba.50",
                    }}
                    _active={{
                      background: "darkRgba.100",
                    }}
                    _focus={{
                      background: "darkRgba.100",
                    }}
                  >
                    プロフィール設定
                  </MenuItem>
                  {/** こちらテスト用のためにコメントアウトで残します*/}
                  {/* <MenuItem
                    onClick={onTestSound}
                    _hover={{
                      background: "darkRgba.50",
                    }}
                    _active={{
                      background: "darkRgba.100",
                    }}
                    _focus={{
                      background: "darkRgba.100",
                    }}
                  >
                    音声のテスト
                  </MenuItem> */}
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Box>
      {!isError && (
        <Flex
          height="52px"
          w="100%"
          borderBottom="1px solid rgba(0,0,0,0.1)"
          p="8px"
        >
          <Text
            p="4px 8px"
            fontSize="16px"
            fontWeight="bold"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {chatName}
          </Text>
          <Spacer />
          <Flex w={callingUsers.length > 0 ? "160px" : "40px"}>
            {viewChatCalling && (
              <ChatIconButton
                onClick={() => {
                  setIsCalling(!isCalling);
                  setIsViewCallingUsers(false);
                }}
                border="1px solid #000000"
                background={isCalling ? "green" : ""}
                ariaLabel={"calling"}
                icon={<MdHeadphones color="#000000" />}
              />
            )}
            {/* <Spacer />
          {callingUsers.length > 0 && (
            <Flex
              background="#EBEFF3"
              border="1px solid var(--Gray-10, #E2E7EC)"
              borderRadius="4px"
              p="4px"
              cursor="pointer"
              onClick={() => setIsViewCallingUsers(!isViewCallingUsers)}
            >
              <Flex mr="24px">
                {callingUsers.map((callingUser, index) => {
                  if (index < 3) {
                    const iconImage = getIconImage(callingUser.userId) ?? "";
                    return iconImage ? (
                      <Image
                        w="24px"
                        h="24px"
                        mr="-6px"
                        key={index}
                        zIndex={3 - index}
                        borderRadius="80%"
                        src={iconImage}
                      />
                    ) : (
                      <Box
                        boxSize="24px"
                        p="4px 2px"
                        w="24px"
                        h="24px"
                        mr="-6px"
                        borderRadius="full"
                        bgColor="#BBC7D4"
                        textAlign="center"
                        key={index}
                        zIndex={3 - index}
                      >
                        <Text fontSize="10px" color="#000000">
                          {callingUser?.name?.slice(0, 2)}
                        </Text>
                      </Box>
                    );
                  }
                })}
              </Flex>
              <Box w="20px" h="20px">
                <Text>{callingUsers.length}</Text>
              </Box>
            </Flex>
          )} */}
          </Flex>
        </Flex>
      )}
      {isViewCallingUsers && (
        <Box
          w="256px"
          maxH="420px"
          overflowY="auto"
          borderRadius="4px"
          background="#FFFFFF"
          boxShadow="0px 4px 30px 0px rgba(0, 0, 0, 0.12)"
          position="absolute"
          right="0"
          zIndex="1"
        >
          <Box>
            <Box borderBottom="1px solid var(--Gray-10, #E2E7EC)" p="8px 16px">
              <Text fontWeight={600} fontSize="16px">
                通話中のスタッフ
              </Text>
            </Box>
            <Box p="8px">
              {callingUsers.map((callingUser, index) => {
                const iconImage = getIconImage(callingUser.userId) ?? "";
                return (
                  <Flex key={index}>
                    {iconImage ? (
                      <Image
                        w="40px"
                        h="40px"
                        borderRadius="80%"
                        src={iconImage}
                      />
                    ) : (
                      <Box
                        boxSize="24px"
                        p="8px 4px"
                        w="40px"
                        h="40px"
                        mr="1px"
                        borderRadius="full"
                        bgColor="#BBC7D4"
                        textAlign="center"
                      >
                        <Text fontSize="14px" color="#000000">
                          {callingUser?.name?.slice(0, 2)}
                        </Text>
                      </Box>
                    )}
                    <Text m="8px 0px 0px 8px" fontWeight={600}>
                      {callingUser.name}
                    </Text>
                  </Flex>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
