import emojiRegex from "emoji-regex";

export const getRandomString = (num = 16) => {
  const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  return Array.from(Array(num))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join("");
};

export const containsOnlyEmoji = (str: string) => {
  const regex = emojiRegex();
  const matches = Array.from(str.matchAll(regex));
  const emojiLength = matches.reduce((acc, match) => acc + match[0].length, 0);

  if (emojiLength === str.length) {
    return true;
  } else {
    return false;
  }
};

const URL_PATTERN = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const isURL = (str: string) => {
  if (!str.startsWith("http") && !str.startsWith("https")) {
    return false;
  }
  return URL_PATTERN.test(str);
};

export const replaceUserId = (userId: string) => {
  let tmpUserId = userId;
  const changeChatList = [".", "#", "$", "[", "]", "@"];
  changeChatList.forEach((changeChat: string) => {
    const regex = new RegExp(`\\${changeChat}`, "g");
    tmpUserId = tmpUserId.replace(regex, "_");
  });
  return tmpUserId;
};

export const replaceFromPeerIdToUserId = (peerId: string) => {
  const parts = peerId.replace("chat-staff-", "").split("-");
  const userId = parts[0];
  return userId;
};
