import { Box, Flex, Spacer, Text, Textarea } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { IoSend } from "react-icons/io5";
import { MESSAGE_TEXT_LIMIT, TEXT } from "src/const";
import { ChatIconButtonBlue } from "src/pages/Main/components/ChatIconButtonBlue";
import { selectedChatAtom } from "src/pages/Main/atoms/selectedChat";
import { useAtomValue } from "jotai";
export const ChatTextArea: FC<{
  callChatTextRef: React.RefObject<HTMLTextAreaElement>;
  requestChat: () => Promise<void>;
}> = ({ callChatTextRef, requestChat }) => {
  const selectedChat = useAtomValue(selectedChatAtom);
  const [message, setMessage] = useState<string>("");
  const [isBoxFocus, setIsBoxFocus] = useState<boolean>(false);
  const [isInputTextNumAlert, setIsInputTextNumAlert] =
    useState<boolean>(false);
  useEffect(() => {
    if (!isInputTextNumAlert && message.length > MESSAGE_TEXT_LIMIT) {
      setIsInputTextNumAlert(true);
    }
    if (isInputTextNumAlert && message.length < MESSAGE_TEXT_LIMIT) {
      setIsInputTextNumAlert(false);
    }
  }, [message, isInputTextNumAlert]);

  const getDraftMessage = (chatId: string) => {
    const draftMessagesText = localStorage.getItem("draft_messages");
    let draftMessages: { [chatId: string]: string } = {};
    if (draftMessagesText) {
      draftMessages = JSON.parse(draftMessagesText);
      const message = draftMessages[chatId];
      return message;
    }
    return "";
  };

  const setDraftMessage = (chatId: string, message: string) => {
    const draftMessagesText = localStorage.getItem("draft_messages");
    let draftMessages: { [chatId: string]: string } = {};
    if (draftMessagesText) {
      draftMessages = JSON.parse(draftMessagesText);
      draftMessages[chatId] = message;
    }
    localStorage.setItem("draft_messages", JSON.stringify(draftMessages));
  };

  useEffect(() => {
    //ルーム切り替え
    if (!selectedChat) return;

    setMessage("");
    const message = getDraftMessage(selectedChat);
    if (message) {
      setMessage(message);
    }
  }, [selectedChat]);

  const textAreaResetHeight = () => {
    if (callChatTextRef.current) {
      callChatTextRef.current.style.height = "35px";
    }
  };

  const handleKeyPress = (e: any) => {
    if (!selectedChat) return;
    if (e.key === "Enter" && (e.altKey || e.ctrlKey || e.metaKey)) {
      requestChat();
      textAreaResetHeight();
      setMessage("");
      setDraftMessage(selectedChat, "");
      // ここで必要な処理を実行します
      e.preventDefault(); // テキストエリアでの改行を防ぐ（オプション）
    }
  };

  return (
    <Box
      w="100%"
      p="8px 8px 8px 8px"
      borderTop="solid 1px #E2E7EC"
      backgroundColor="#fff"
    >
      <Box
        w="100%"
        borderRadius="8px"
        background="rgba(244, 246, 248, 1)"
        outline={
          isBoxFocus
            ? "1px solid rgba(34, 197, 94, 1)"
            : "0px solid rgba(0, 0, 0, 0)"
        }
        transition="0.08s ease"
      >
        <Textarea
          ref={callChatTextRef}
          rows={1}
          style={{
            width: "100%",
            WebkitAppearance: "none",
            resize: "none",
            maxHeight: "360px",
          }}
          border="0px"
          _focus={{ outline: "none", boxShadow: "none" }}
          onFocus={() => {
            setIsBoxFocus(true);
          }}
          onBlur={() => {
            setIsBoxFocus(false);
          }}
          onKeyDown={handleKeyPress}
          onChange={(e) => {
            if (!selectedChat) return;
            setMessage(e.target.value);
            setDraftMessage(selectedChat, e.target.value);
            e.target.style.height = isInputTextNumAlert ? "70px" : "35px";
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          value={message}
          placeholder="メッセージを入力"
          fontSize="14px"
          overflow="hidden"
          borderRadius="8px"
        />
        {isInputTextNumAlert && (
          <Flex p="0px 14px">
            <Box>
              <Text fontSize="14px" color="#EB1438">
                {TEXT.MESSAGE.CHARACTER_COUNT_OVER_ON_CHAT_MESSAGE_AREA}
              </Text>
            </Box>
            <Spacer />
            <Box>
              <Text fontSize="14px" color="#EB1438">
                {message.length}/{MESSAGE_TEXT_LIMIT}
              </Text>
            </Box>
          </Flex>
        )}
        <Flex justifyContent="right" padding="8px">
          <ChatIconButtonBlue
            icon={<IoSend fontSize="20px" />}
            onClick={() => {
              if (!selectedChat) return;
              requestChat();
              setMessage("");
              setDraftMessage(selectedChat, "");
              textAreaResetHeight();
            }}
            disabled={
              message.length === 0 || isInputTextNumAlert ? true : false
            }
          />
        </Flex>
      </Box>
    </Box>
  );
};
