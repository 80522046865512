import { useState } from "react";
import {
  Box,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

export const InputArea = ({
  text,
  placeholderText,
  defaultValue,
  handleChange,
  onEnter,
}: {
  text: string;
  placeholderText: string;
  defaultValue?: string | number | readonly string[] | undefined;
  handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onEnter?: () => void;
}) => {
  const [isPasswordDisplay, setIsPasswordDisplay] = useState<boolean>(true);
  return (
    <Box>
      <Text fontSize="14px">{text}</Text>
      <Box width="320px" mt="5px">
        <InputGroup fontSize="16px" size="md">
          <Input
            width="320px"
            height="50px"
            fontSize="16px"
            border="1px solid rgba(0, 0, 0, 0.13)"
            defaultValue={defaultValue}
            onChange={handleChange}
            pl="16px"
            placeholder={placeholderText}
            type={
              text.includes("パスワード")
                ? isPasswordDisplay
                  ? "password"
                  : "text"
                : "text"
            }
            onKeyDown={(e: any) => {
              if (e.keyCode === 13 && onEnter) {
                onEnter();
              }
            }}
          />
          {text.includes("パスワード") && (
            <InputRightElement w="50px" h="50px">
              <IconButton
                bg="transparent"
                _hover={{ bg: "transparent" }}
                color="primary.500"
                outline="none"
                variant="ghost"
                aria-label={
                  isPasswordDisplay ? "Mask password" : "Reveal password"
                }
                icon={
                  isPasswordDisplay ? (
                    <IoEyeOffOutline color="primary.500" fontSize="24px" />
                  ) : (
                    <IoEyeOutline color="primary.500" fontSize="24px" />
                  )
                }
                onClick={() => {
                  setIsPasswordDisplay(!isPasswordDisplay);
                }}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Box>
    </Box>
  );
};
