import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Cookies, CookiesProvider } from "react-cookie";
import { makeOperation } from "@urql/core";
import { authExchange } from "@urql/exchange-auth";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from "urql";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import "./index.css";
import { cookieKeyToken } from "./util/token";

interface AuthState {
  token: string;
}
const authConfig = {
  getAuth: async ({ authState }: any) => {
    if (!authState) {
      const cookies = new Cookies();
      let token = cookies.get(cookieKeyToken);
      if (token) {
        return { token };
      }
      return null;
    }
    return null;
  },
  didAuthError: ({ error }: any) => {
    const isAuthError = error.graphQLErrors?.[0]?.extensions?.status === 401;
    return isAuthError;
  },
  addAuthToOperation: ({ authState, operation }: any) => {
    if (!authState || !authState.token) {
      return operation;
    }

    const fetchOptions =
      typeof operation.context.fetchOptions === "function"
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `bearer ${authState.token}`,
        },
      },
    });
  },
};

const client = createClient({
  url: process.env.REACT_APP_AGASA_API_URL ?? "http://localhost:5001/query",
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange<AuthState>(authConfig),
    fetchExchange,
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <ChakraProvider theme={theme}>
        <UrqlProvider value={client}>
          <App />
        </UrqlProvider>
      </ChakraProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
