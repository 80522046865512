import { API_PASS } from "src/const";
import md5 from "md5";

/** VOytインカムログイン処理*/
export const requestLogin = async (account: string, password: string) => {
  const url = process.env.REACT_APP_INCOM_API_URL;
  const applicationId = process.env.REACT_APP_INCOM_APPLICATION_ID;
  const contractId = process.env.REACT_APP_INCOM_CONTRACT_ID;
  if (!applicationId || !url || !contractId) {
    console.error(
      "環境変数 REACT_APP_INCOM_API_URL, REACT_APP_API_KEYの何方かが設定されていません"
    );
    return;
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  // MD5でハッシュ化

  try {
    const res = await fetch(`${url}/${API_PASS.LOGIN}`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        application_id: applicationId,
        contract_id: contractId,
        account,
        password: md5(password),
      }),
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};
