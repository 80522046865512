export const getNowTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  const currentTimeString = `${year}-${month.toString().padStart(2, "0")}-${date
    .toString()
    .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return currentTimeString;
};

export const transformChatTime = (datetimestamp: string) => {
  // ISO 8601形式のタイムスタンプをJavaScriptのDateオブジェクトに変換
  const date = new Date(datetimestamp);

  // 時間と分と秒を取得し、2桁にフォーマットする
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  // hh:mm:ss 形式で返す
  return `${hours}:${minutes}:${seconds}`;
};
