import { atom } from "jotai";

export enum ErrorType {
  /** voytのAPIログインに失敗 */
  VOYT_LOGIN_FAILED = "voyt_login_failed",
  /** voytのAPIグループ取得に失敗 */
  GET_VOYT_GROUP_FAILED = "get_voyt_group_failed",
}

export const errorTypeAtom = atom<ErrorType | undefined>(undefined);
