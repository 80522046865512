import { FC, ReactNode } from "react";

import styled from "styled-components";

export const ChatIconButtonBlue: FC<{
  icon: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}> = ({ icon, onClick, disabled }) => {
  return (
    <IconButton
      onClick={() => {
        if (!disabled) onClick();
      }}
      disabled={disabled}
    >
      {icon}
    </IconButton>
  );
};

const IconButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 197, 94, 1);
  cursor: pointer;
  border-radius: 4px;
  transition: 0.16s ease;
  padding: 0 0 0 2px;
  color: #fff;
  &:hover {
    background-color: rgba(27, 158, 75, 1);
  }
  &:active {
    background-color: rgba(20, 118, 56, 1);
  }
  &:disabled {
    color: #bbc7d4;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;
