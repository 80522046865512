import {
  Flex,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";
import { FC, memo, MouseEvent } from "react";

type Props = {
  toolTipProps: TooltipProps;
  iconButtonProps: Omit<IconButtonProps, "onClick">;
  handleClickButton: (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
};

/**
 * tooltip をデフォルトで表示させたボタン
 *
 * @param toolTipProps tooltip のベーススタイル
 * @param iconButtonProps Button のベーススタイル
 * @param handleClickButton ボタンクリックによって行いたい処理
 */

export const TooltipButton: FC<Props> = memo(
  ({ toolTipProps, iconButtonProps, handleClickButton }: Props) => {
    return (
      <Flex>
        <Tooltip {...toolTipProps}>
          <IconButton
            {...iconButtonProps}
            onClick={(e) => handleClickButton(e)}
            cursor={iconButtonProps.cursor ? iconButtonProps.cursor : "pointer"}
            _hover={{
              background: "darkRgba.50",
            }}
            _active={{
              background: "darkRgba.100",
            }}
          />
        </Tooltip>
      </Flex>
    );
  }
);
