import { Box } from "@chakra-ui/react";
import { FC, ReactNode, useState } from "react";
import { Scrollbars } from "rc-scrollbars";

type Props = {
  children?: ReactNode;
  height?: string;
};

export const ScrollBar: FC<Props> = (props: Props) => {
  const { children, height } = props;

  const [isTop, setIsTop] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);

  const handleUpdate = (values: any) => {
    if (values.scrollTop > 8) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
    if (values.clientHeight < values.scrollHeight) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
    if (values.scrollHeight - values.clientHeight - values.scrollTop < 8) {
      setIsBottom(false);
    }
  };

  return (
    <Box
      height={height ? height : "auto"}
      position="relative"
      zIndex="1"
      _before={{
        content: `" "`,
        display: "block",
        position: "absolute",
        width: "100%",
        height: "16px",
        top: "0",
        left: "0",
        background:
          "linear-gradient(to bottom ,rgba(0, 0, 0, 0.12) 0, rgba(0,0,0,0.06) 30%, rgba(0,0,0,0) 80%)",
        opacity: isTop ? "1" : "0",
        transition: "opacity 0.25s ease-in-out",
        zIndex: "3",
      }}
      _after={{
        content: `" "`,
        display: "block",
        position: "absolute",
        width: "100%",
        height: "16px",
        bottom: "0",
        left: "0",
        background:
          "linear-gradient(to top ,rgba(0, 0, 0, 0.16) 0%, rgba(0,0,0,0.08) 30%, rgba(0,0,0,0) 80%)",
        opacity: isBottom ? "1" : "0",
        transition: "opacity 0.25s ease-in-out",
        zIndex: "4",
      }}
    >
      <Scrollbars
        style={{
          height: "100%",
          zIndex: "2",
        }}
        onUpdate={handleUpdate}
      >
        {children}
      </Scrollbars>
      {/* <Box
        ref={wrapperRef}
        height={height ? height : "auto"}
        overflow="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
            position: "absolute",
            right: "0",
            top: "0",
            display: isScroll ? "block" : "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `rgba(0, 0, 0, 0.1)`,
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: `rgba(0, 0, 0, 0.2)`,
          },
        }}
      >
        {children}
      </Box> */}
    </Box>
  );
};
