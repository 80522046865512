import { useMemo, FC } from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import { useLogin } from "src/pages/Login/hooks/useLogin";
import { InputArea } from "src/components/InputArea";
import { Header } from "src/components/Header";
import { PATH, TEXT } from "src/const";
import { Link as ReactLink } from "react-router-dom";

export const Login: FC<{ loginType: string }> = ({ loginType }) => {
  const {
    login,
    userId,
    setUserId,
    password,
    setPassword,
    shopId,
    setShopId,
    alertText,
  } = useLogin();

  const isActive: boolean = useMemo(() => {
    if (loginType === "shop") {
      return userId && password && shopId ? true : false;
    } else {
      return userId && password ? true : false;
    }
  }, [userId, password, shopId, loginType]);

  return (
    <Box textAlign="center" height="95vh">
      <Header text={TEXT.COMMON.HEADER_OPERATION_DESCRIPTION} />
      <Box
        textAlign="left"
        w="390px"
        m="auto"
        boxShadow="0px 1px 6px rgba(0, 0, 0, 0.16)"
        borderRadius="4px"
      >
        <Box p="10px 10px 30px 10px">
          {alertText && (
            <Text mt="10px" color="red" textAlign="right">
              {alertText}
            </Text>
          )}
          <Box p="22px 28px 20px">
            <InputArea
              text="スタッフID"
              defaultValue={userId}
              handleChange={(e) => {
                setUserId(e.target.value);
              }}
              placeholderText="スタッフID"
            />
          </Box>
          <Box p="0 28px 20px">
            <InputArea
              text="パスワード"
              defaultValue={password}
              handleChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholderText="パスワード"
              onEnter={() => {
                if (isActive) {
                  login(PATH.CHAT);
                }
              }}
            />
          </Box>
          {loginType === "shop" && (
            <Box p="0px 28px 31px">
              <InputArea
                text="店舗ID"
                defaultValue={shopId}
                handleChange={(e) => {
                  setShopId(e.target.value);
                }}
                placeholderText="店舗ID"
              />
            </Box>
          )}
          <Box h="50px">
            <Box
              float="right"
              w="100px"
              mr="28px"
              mb="20px"
              h="40px"
              mt="5px"
              background={isActive ? "#00A0E9" : "rgba(0, 0, 0, 0.12)"}
              borderRadius="4px"
              cursor={isActive ? "pointer" : "not-allowed"}
              _hover={{
                bg: isActive
                  ? "rgba(0, 160, 233, 0.50)"
                  : "rgba(0, 0, 0, 0.12)",
              }}
              onClick={() => {
                if (isActive) {
                  login(PATH.CHAT);
                }
              }}
            >
              <Text
                pt="5px"
                fontSize="16px"
                fontWeight="400"
                color={isActive ? "#FFFFFF" : "rgba(0, 0, 0, 0.26)"}
                textAlign="center"
              >
                ログイン
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box m="32px auto" textAlign="center" width="380px">
        <Box textAlign="center">
          <Link
            fontSize="14px"
            color="primary.500"
            cursor="pointer"
            textAlign="center"
            as={ReactLink}
            to={process.env.REACT_APP_PASSWORD_RESET_URL ?? ""}
          >
            パスワードをお忘れの場合
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
