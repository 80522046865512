import { useState, useEffect, useCallback } from "react";
import {
  ALLChat,
  Chat,
  ChatGroupType,
  SendUserRoleType
} from "src/interface/Chat";
import { Cookies } from "react-cookie";
import { cookieKeyVoytToken } from "src/util/token";
import { isDevEnv } from "src/util/env";
import { requestLogin } from "src/api/voytApi/requestLogin";
import { useAtomValue, useSetAtom } from "jotai";
import { meVoytAtom, meAtom } from "src/pages/Main/atoms/me";
import { requestGetGroup } from "src/api/voytApi/requestGetGroup";
import { requestGetSpeechList } from "src/api/voytApi/requestGetSpeechList";
import { requestSpeech } from "src/api/voytApi/requestSpeech";
import { replaceUserId } from "src/util/getString";
import { Role } from "src/gql/generated/graphql";
import { selectedChatAtom } from "src/pages/Main/atoms/selectedChat";
import { isSendMessageLoadingAtom } from "src/pages/Main/atoms/isSendMessageLoading";
import { errorTypeAtom, ErrorType } from "src/pages/Main/atoms/errorType";

// voytのインカムAPIを実行する
export const useVoytIntercom = (
  allChat: ALLChat | null,
  setAllChat: (allChat: ALLChat | null) => void
) => {
  const [token, setToken] = useState<string>();
  const setIsSendMessageLoading = useSetAtom(isSendMessageLoadingAtom);
  const [voytIntercomGroupList, setIntercomGroupList] = useState<
    { id: string; name: string }[]
  >([]);
  const meVoyt = useAtomValue(meVoytAtom);
  const me = useAtomValue(meAtom);
  const [lastGetChatTime, setLastGetChatTime] = useState<string>();
  const [lastGetChatTimeOnRoom, setLastGetChatTimeOnRoom] = useState<string>();
  const selectedChat = useAtomValue(selectedChatAtom);
  const setErrorType = useSetAtom(errorTypeAtom)

  // インカムAPIのtokenを取得する
  const getIntercomToken = async () => {
    // TODO: インカムのtokenを取得してstateにセットする
    if (!meVoyt) return;
    if (!meVoyt.userId || !meVoyt.password) return;
    const result = await requestLogin(meVoyt.userId, meVoyt.password);
    const cookie = new Cookies();
    if (!result || result[0] !== 200) {
      console.error("voyt login failed");
      setErrorType(ErrorType.VOYT_LOGIN_FAILED);
      cookie.remove(cookieKeyVoytToken, {
        maxAge: 10 * 24 * 60 * 60,
        path: "/",
        secure: true,
        domain: isDevEnv() ? "localhost" : ".rura.tokyo",
      });
      setToken(undefined);
      return;
    } 
    const data = result[1]
    
    cookie.set(cookieKeyVoytToken, data.token_key, {
      maxAge: 10 * 24 * 60 * 60,
      path: "/",
      domain: isDevEnv() ? "localhost" : ".rura.tokyo",
    });
    setToken(data.token_key);
    return data.token_key;
  };

  // 所属しているインカムグループの一覧を取得する
  const getIntercomGroupList = async () => {
    const result = await requestGetGroup();
    // TODO: 10分の認証失敗時の処理を追加する
    if (!result) return [];
    if (result[0] === 401) {
      setToken(undefined);
      return {};
    }
    const data = result[1];
    // リストを成形する idとnameのみ返却する
    return data.map((group: ChatGroupType) => {
      return {
        id: group.id,
        name: group.name,
      };
    })
  };

  const sendIntercomMessage = useCallback(async (groupId: string, text: string) => {
    setIsSendMessageLoading(true);
    const result = await requestSpeech(groupId, text, me?.role);
    if (!result) return;
    if (result[0] === 401) { 
      console.error("認証に失敗して送信に失敗しました")
      // TODO: 送信失敗時のデザインになるように変更する
    }
    // インカム情報を再取得する
    getAllIntercomData(groupId)
    return;
  }, [me?.role])

  const getChatUserId = (voytUserAccount: string) => {
    // 自分の場合だけはchatUserIdに自分のIDを入れる
    if (!meVoyt) return;
    if (!me) return;
    if (meVoyt.userId === voytUserAccount) {
      return replaceUserId(me?.datastoreId ?? "");
    }
  };

  const getSendUserRole = (userRole: Role | undefined) => {
    if (!userRole) return SendUserRoleType.IntercomApp;
    if (userRole === Role.Admin || userRole === Role.Owner || userRole === Role.User) {
      return SendUserRoleType.RemoteUser;
    }
    if (userRole === Role.Supporter) {
      return SendUserRoleType.SupportUser;
    }
  }

  const getAllIntercomData = async (incomGroupId: string | undefined = undefined) => {
    // インカムの全データを取得する
    // タイムスタンプを使って、すでに取得済みのデータ移行のデータのみ取得するようにする
    const allIntercomData: ALLChat = allChat ?? {};
    // voytIntercomGroupListをincomGroupIdがあるときはそのグループのみ取得する
    const targetGroupList = incomGroupId ? voytIntercomGroupList.filter((group) => group.id === incomGroupId) : voytIntercomGroupList;
    await targetGroupList.map(async (group) => {
      const result = await requestGetSpeechList(
        group.id,
        incomGroupId ? lastGetChatTimeOnRoom : lastGetChatTime
      );
      if (!result) return {};
      if (result[0] === 401) {
        setToken(undefined);
        return {};
      }
      if (incomGroupId) {
        setLastGetChatTimeOnRoom(new Date().toISOString());
      } else {
        setLastGetChatTime(new Date().toISOString());
      }
      // TODO: 10分の認証失敗時の処理を追加する
      if (!result) return {};
      const speechList = result[1];
      // TODO: speechのinterfaceを設定する
      speechList.map((speech: any) => {
        // allIntercomDataにgroup.idがないときの処理を追加する
        if (!allIntercomData[group.id]) {
          allIntercomData[group.id] = {};
        }
        // NOTE:自分の場合だけはchatUserIdに自分のIDを入れる
        allIntercomData[group.id][speech.speech_id] = {
          id: speech.speech_id,
          text: speech.recog_content,
          userType: "user",
          chatUserId: getChatUserId(speech.user.account),
          intercomAccount: {
            id: speech.user.id,
            account: speech.user.account,
            name: speech.user.name,
          },
          timestamp: speech.speech_endtimestamp,
          messageType: "text",
          userRole: getSendUserRole(speech.meta_data?.memo?.memo_body),
        } as Chat;
      });
      setIsSendMessageLoading(false);
      // allIntercomDataのキーにtargetGroupListのIDがすべて存在するか確認する
      if (targetGroupList.length === Object.keys(allIntercomData).length) {
        setAllChat(allIntercomData);
      }
      return allIntercomData;
    });
    
  };

  useEffect(() => {
    if (!meVoyt) return;
    if (token) return
    // インカムのユーザID, パスワードでログインしてtokenを取得する
    getIntercomToken().then(async () => {
      const intercomGroupList = await getIntercomGroupList();
      if (!intercomGroupList) {
        setErrorType(ErrorType.GET_VOYT_GROUP_FAILED);
      } else {
        setErrorType(undefined);
      }
      setIntercomGroupList(intercomGroupList);
    });
  }, [meVoyt, token]);

  useEffect(() => {
    if (!token) return;
    if (!me) return;
    if (!meVoyt) return;
    if (voytIntercomGroupList.length === 0) return;
    if (!lastGetChatTime) { getAllIntercomData(); }
    // 30秒ごとにインカム情報を取得する
    const interval = setInterval(() => {
      getAllIntercomData();
    }, 30000);
    return () => clearInterval(interval);
  }, [me, meVoyt, voytIntercomGroupList, lastGetChatTime, token]);

  useEffect(() => {
    if (!token) return;
    if (!me) return;
        // 選択されたチャットが開かれている時には5秒に一回取得する
        
    if (!meVoyt) return;
    if (voytIntercomGroupList.length === 0) return;
    if (!selectedChat) return;
    const interval = setInterval(() => {
      getAllIntercomData(selectedChat);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedChat, lastGetChatTimeOnRoom, token]);

  useEffect(() => {
    if (!selectedChat) return;
    setLastGetChatTimeOnRoom(lastGetChatTime);
  }, [selectedChat]);

  return {
    voytIntercomGroupList,
    sendIntercomMessage,
    lastGetChatTime,
    lastGetChatTimeOnRoom,
  };
};