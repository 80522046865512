import { FC } from "react";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { ChatGroupType } from "src/interface/Chat";
import { useSetAtom, useAtomValue } from "jotai";
import { selectedChatAtom } from "src/pages/Main/atoms/selectedChat";
import { usersAtom } from "src/pages/Main/atoms/users";
import { getUser } from "src/util/realtimedb/updateUserInfo";
export const SidebarItem: FC<{
  chatGroup: ChatGroupType;
  checkChatRoom: (chatId?: string) => boolean;
}> = ({ chatGroup, checkChatRoom }) => {
  const setSelectedChat = useSetAtom(selectedChatAtom);
  const users = useAtomValue(usersAtom);
  return (
    <Box w="100%" h="36px" mt="4px">
      <Tooltip label={chatGroup.tooltipText} placement="top">
        <Box
          w="100%"
          h="100%"
          p="8px"
          m="4px 0px"
          background={chatGroup.isSelected ? "rgba(211, 243, 223, 1)" : ""}
          borderRadius="4px"
          cursor="pointer"
          position="relative"
          onClick={() => {
            setSelectedChat(chatGroup.id);
            checkChatRoom(chatGroup.id);
          }}
          _hover={{ background: "#E2E7EC" }}
          _active={{ background: "#CED7E0" }}
        >
          <Text
            fontWeight="400"
            fontSize="14px"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            pr={chatGroup.unreadMessgesNum > 0 ? "13px" : ""}
            color={chatGroup.unreadMessgesNum > 0 ? "#37352F" : "#878682"}
          >
            {chatGroup.chatType === "users"
              ? getUser(users, chatGroup.name)?.name
              : chatGroup.name}
          </Text>
          {chatGroup.unreadMessgesNum > 0 && (
            <Box
              position="absolute"
              top="50%"
              right="8px"
              transform="translate(0,-50%)"
              textAlign="center"
              background="rgba(34, 197, 94, 1)"
              borderRadius="80%"
              w="5px"
              h="5px"
            ></Box>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};
