import { FC, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Header } from "src/components/Header";
import { useSetting } from "src/pages/Setting/hooks/useSetting";
import { PATH } from "src/const";

const Setting: FC = () => {
  const [isActive, setActive] = useState<boolean>(true);
  const { userName, roomId, companyName } = useSetting();

  return (
    <Box textAlign="center" height="95vh">
      <Header text="入力して接続開始を押してください。" />
      <Box
        textAlign="left"
        width="368px"
        m="auto"
        boxShadow="0px 1px 8px rgba(0, 0, 0, 0.16)"
        borderRadius="4px"
      >
        <Box p="24px">
          <Text>カンパニー: {companyName}</Text>
          <Text>ユーザ: {userName}</Text>
          <Box
            width="320px"
            height="50px"
            mt="5px"
            background={isActive ? "#00A0E9" : "rgba(0, 0, 0, 0.12)"}
            borderRadius="4px"
            cursor={isActive ? "pointer" : "not-allowed"}
            _hover={{
              bg: isActive ? "rgba(0, 160, 233, 0.50)" : "rgba(0, 0, 0, 0.12)",
            }}
            onClick={() => {
              if (isActive) {
                window.location.href = `${PATH.CHAT}?room_id=${roomId}`;
              }
            }}
          >
            <Text
              pt="10px"
              fontSize="18px"
              fontWeight="500"
              color={isActive ? "#FFFFFF" : "rgba(0, 0, 0, 0.26)"}
              textAlign="center"
            >
              接続開始
            </Text>
          </Box>
        </Box>
      </Box>
      <Box m="64px auto" textAlign="left" width="380px">
        <Text fontSize="12px" m="0">
          ※ユーザID,
          パスワードが不明な場合は、御社管理者にお問い合わせください。
        </Text>
      </Box>
    </Box>
  );
};
export default Setting;
