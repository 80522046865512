import { Box, Icon, Text, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import { IoChatbubblesSharp } from "react-icons/io5";
export const MinimumChatIcon: FC<{
  unreadAllChatMessages: number;
  setIsView: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ unreadAllChatMessages, setIsView }) => {
  const minimizeChat = (isMinimize: boolean) => {
    setIsView(!isMinimize);
  };

  return (
    <Tooltip
      hasArrow
      label={"チャットを表示"}
      placement="top"
      borderRadius="4px"
      backgroundColor="rgba(97, 97, 97, 0.9)"
      maxH="50px"
    >
      <Box p="5px" w="50px" h="50px" position="relative">
        {unreadAllChatMessages > 0 && (
          <Box
            textAlign="center"
            background="red"
            borderRadius="80%"
            w="20px"
            h="20px"
            position="absolute"
            right="0"
            top="0"
          >
            <Text color="white">{unreadAllChatMessages}</Text>
          </Box>
        )}
        <Icon
          as={IoChatbubblesSharp}
          aria-label="チャットの表示"
          borderRadius="5px"
          cursor="pointer"
          background="#FFFFFF"
          w="40px"
          h="40px"
          _hover={{
            background: "rgba(0,0,0, 0.1)",
          }}
          _active={{
            background: "#DCD400",
          }}
          onClick={() => {
            minimizeChat(false);
          }}
        />
      </Box>
    </Tooltip>
  );
};
