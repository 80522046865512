import { API_PASS } from "src/const";
import { Cookies } from "react-cookie";
import { cookieKeyVoytToken } from "src/util/token";

/** Voytインカムグループ取得処理*/
export const requestGetGroup = async () => {
  const cookie = new Cookies();
  const token = cookie.get(cookieKeyVoytToken);
  const url = process.env.REACT_APP_INCOM_FUNCTION_API_URL;
  if (!url) {
    console.error(
      "環境変数 REACT_APP_INCOM_FUNCTION_API_URLが設定されていません"
    );
    return;
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (!token) {
    console.error("tokenがありません");
    return [await 400, undefined];
  }
  headers.append("X-AUTH-TOKEN", token);

  try {
    const params = { compress_voice: "true", lockless_speech: "true" };
    const apiUrl = `${url}/${API_PASS.GET_GROUP}`
    // apiUrlにparamsを追加する
    const urlWithParams = new URL(apiUrl)
    urlWithParams.search = new URLSearchParams(params).toString()

    const res = await fetch(urlWithParams, {
      method: "GET",
      headers,
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};
