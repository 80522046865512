import { Cookies } from "react-cookie";
import { useEffect } from "react";
import { cookieKeyToken } from "src/util/token";
import { PATH } from "src/const";

/** sessionないにtokenがあるかどうかのチェック*/
export const useCheckAuth = () => {
  useEffect(() => {
    // ログインページの場合はチェックしない
    if (
      window.location.pathname === PATH.LOGIN ||
      window.location.pathname === PATH.SHOP_LOGIN
    )
      return;
    const intervalId = setInterval(() => {
      const cookies = new Cookies();
      const token = cookies.get(cookieKeyToken);
      if (!token) {
        window.location.href = "/login";
        clearInterval(intervalId);
      }
    }, 1000 * 60);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
};
