import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { useSetAtom } from "jotai";
import { databaseAtom } from "src/pages/Main/atoms/firebase";

export const useFireBase = () => {
  const setDatabase = useSetAtom(databaseAtom);
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);
  setDatabase(database);

  return { database };
};
