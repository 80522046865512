import { Image } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import styled from "styled-components";

export const ChatIconButton: FC<{
  icon?: ReactNode;
  ariaLabel: string;
  onClick?: () => void;
  color?: string;
  background?: string;
  disabled?: boolean;
  blue?: boolean;
  img?: string;
  ml?: string;
  border?: string;
}> = ({ icon, onClick, disabled, img, background, border, ml = "0px" }) => {
  return (
    <IconButton
      style={{
        marginLeft: ml,
        background: background ? background : "",
        border: border ? border : "none",
      }}
      onClick={() => {
        if (!disabled && onClick !== undefined) onClick();
      }}
      disabled={disabled}
    >
      {img ? <Image src={img} width="16px" height="16px" /> : icon}
    </IconButton>
  );
};

const IconButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.16s ease;
  color: #51687f;
  &:hover {
    background: rgba(226, 231, 236, 1);
  }
  &:active {
    background: rgba(206, 215, 224, 1);
  }
  &:disabled {
    color: #bbc7d4;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;
